/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import debug from 'debug'
import * as t from 'io-ts'
import { deferredAction } from 'redux-saga-try-catch'
import { call, cancel, cancelled, delay, fork, put, take, takeEvery } from 'typed-redux-saga/macro'

import type { SourceFromPerspectiveService } from '@/codecs'
import { ImplantBakeStatus } from '@/codecs'
import * as Store from '@/store'
import { getDownloadSourceModalPayload } from '@/store/store.utils'
import type { MiddlewaresIO } from '@/store/store.utils'
import { get, getRequiredValue, QueryString, UUID } from '@/utilities/codec'
import { isNotNil } from '@/utilities/is-not'

import * as actions from './source.actions'
import { stopPollingImplantBakeStatus } from './source.actions'
import { normalizeSources } from './source.schema'
import { normalizeSource } from './source.selectors'
import { sourceSlice } from './source.slice'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:sources')

const normalizeSourceForStore = (source: SourceFromPerspectiveService) => {
  const { id, name, defaultPerspectiveId, deleted, parameters, sourceType, createTime, updateTime } = source
  return {
    id,
    name,
    default_perspective_id: defaultPerspectiveId,
    deleted,
    parameters,
    source_type: sourceType,
    create_time: createTime,
    update_time: updateTime,
  }
}

// Note: in the future may need a switch case here on the source type (v0 'ATTACK_TARGETED_IMPLANT' only)
export function* _createImplant(io: MiddlewaresIO, action: ReturnType<typeof actions.createImplant>) {
  const payload = action.payload

  const implant = yield* call(io.api.attack.postImplant, payload)

  const newImplantId = implant.sourceId

  const source = yield* call(io.api.perspective.getSource, newImplantId)

  const normalizedSourceForStore = normalizeSourceForStore(source)

  yield* put(sourceSlice.actions.updateSource(normalizedSourceForStore))

  // determine which modal to display upon user submitting source creation by assessing the implant's bake status
  const modalPayload = getDownloadSourceModalPayload(normalizeSource(normalizedSourceForStore))

  yield* put(Store.UIActions.SHOW_MODAL(modalPayload))

  return source
}

function* _startPollingImplantBakeStatus(
  _io: MiddlewaresIO,
  action: ReturnType<typeof actions.startPollingImplantBakeStatus>,
) {
  log(`stop polling for source id: ${action.payload.id}`)
  const pollImplantBakeStatusTask = yield* fork(checkImplantBakeStatusLoop, _io, action)

  // wait and listen for this so that we can execute the next thing (cancelling the task)
  yield take(stopPollingImplantBakeStatus)

  yield cancel(pollImplantBakeStatusTask)
}

function* checkImplantBakeStatusLoop(
  io: MiddlewaresIO,
  action: ReturnType<typeof actions.startPollingImplantBakeStatus>,
) {
  const { id } = action.payload

  while (true)
    try {
      const source = yield* call(io.api.perspective.getSource, id)

      const normalizedSourceForStore = normalizeSourceForStore(source)

      yield* put(sourceSlice.actions.updateSource(normalizedSourceForStore))

      const sourceWithExtractedParameters = normalizeSource(normalizedSourceForStore)

      const validated = getRequiredValue(
        sourceWithExtractedParameters,
        t.type({ 'implant.bake.status': ImplantBakeStatus }),
      )

      const newImplantBakeStatus = isNotNil(validated) ? validated['implant.bake.status'] : undefined

      if (isNotNil(newImplantBakeStatus) && newImplantBakeStatus !== 'BAKE_STATUS_TYPE_PENDING') {
        const modalPayload = getDownloadSourceModalPayload(sourceWithExtractedParameters)

        // upon a status update, display an updated modal
        yield* put(Store.UIActions.SHOW_MODAL(modalPayload))

        // dispatch action to stop polling
        yield* put(stopPollingImplantBakeStatus())

        break
      }

      // delay 1 second until next attempt
      yield* delay(1000)
    } finally {
      // once polling is cancelled/stopped, we end up in this block
      if (yield* cancelled()) {
        log(`stop polling for source id: ${id}`)
      }
    }
}

export function* _editDNS(io: MiddlewaresIO, action: ReturnType<typeof actions.editDNS>) {
  const payload = action.payload

  yield* call(io.api.attack.putImplant, payload)

  const implantId = payload.id

  const source = yield* call(io.api.perspective.getSource, implantId)

  const normalizedSource = normalizeSourceForStore(source)

  yield* put(sourceSlice.actions.updateSource(normalizedSource))

  return normalizedSource
}

export function* _deleteSource(io: MiddlewaresIO, action: ReturnType<typeof actions.deleteSource>) {
  const id = get(action.payload, UUID)

  yield* call(io.api.attack.deleteImplant, id)

  const deletedSource = yield* call(io.api.perspective.getSource, id)

  const normalizedDeletedSource = normalizeSourceForStore(deletedSource)

  yield* put(sourceSlice.actions.updateSourceDelete(normalizedDeletedSource))
}

export function* _fetchSources(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchSources>) {
  const queryString = get(action.payload, t.union([QueryString, t.literal('')]))

  const { sources } = yield* call(io.api.perspective.getSources, queryString)

  const sourcesMappedToProperKeys = sources.map((source) => normalizeSourceForStore(source))

  // only want to account for sources that have not been marked as deleted
  const filteredData = sourcesMappedToProperKeys.filter((source) => source.deleted === false)

  if (action.meta.persist) {
    // TBD - revisit
    yield* put(sourceSlice.actions.updateSources(normalizeSources(filteredData)))
    // @TODO: determine if this is needed and if this is what we should do
    // yield* put(
    //   sourceSlice.actions.updateSourcePagination({
    //     total: response.total,
    //     offset: 0,
    //     count: response.total,
    //   }),
    // )
  }

  return filteredData
}

export function* _fetchSource(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchSource>) {
  const sourceId = get(action.payload, UUID)

  const source = yield* call(io.api.perspective.getSource, sourceId)

  const normalizedSource = normalizeSourceForStore(source)

  // don't update the store if a source has been marked as deleted
  if (normalizedSource.deleted === true) {
    return normalizedSource
  }

  yield* put(sourceSlice.actions.updateSource(normalizedSource))

  return normalizedSource
}

export function* _fetchSourceTotals(io: MiddlewaresIO, _action: ReturnType<typeof actions.fetchSourceTotals>) {
  const { sources } = yield* call(io.api.perspective.getSources)

  // only want to account for sources that have not been marked as deleted
  const filteredData = sources.filter((source) => source.deleted === false)

  const total = filteredData.length

  const totals = {
    unfiltered: total,
    unaffiliated: total,
  }

  yield* put(sourceSlice.actions.updateSourceTotals(totals))

  return {
    total: totals.unfiltered,
    unaffiliatedTotal: totals.unaffiliated,
  }
}

export function* sourceSagasRoot(io: MiddlewaresIO) {
  yield takeEvery(actions.createImplant.toString(), deferredAction(_createImplant, io))
  yield takeEvery(actions.startPollingImplantBakeStatus.toString(), deferredAction(_startPollingImplantBakeStatus, io))
  yield takeEvery(actions.deleteSource.toString(), deferredAction(_deleteSource, io))
  yield takeEvery(actions.editDNS.toString(), deferredAction(_editDNS, io))
  yield takeEvery(actions.fetchSource.toString(), deferredAction(_fetchSource, io))
  yield takeEvery(actions.fetchSources.toString(), deferredAction(_fetchSources, io))
  yield takeEvery(actions.fetchSourceTotals.toString(), deferredAction(_fetchSourceTotals, io))
}
