/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { AuthoritativeEntityType } from '../entity.codecs/platform-entity-types'
import { Link, PaginatedLink, PaginationMeta } from '../json-api.codecs'
import { Parameter, TriggerCriteria } from './trigger-criteria-parameters'

// ---------------------------------------------------------------------------

// ACS doc singular matching_entity_type  https://randori.atlassian.net/wiki/spaces/PROD/pages/2156167359/WIP+Activity+Content+Service#Frontend-API

const _CreatedLastUpdated = t.type({
  author_id: t.string, // email
  date: t.string, // timestamp
})

const _Mitre = t.type({
  mitigations: t.array(t.string),
  tactics: t.array(t.string),
  techniques: t.array(t.string),
})

const _Objective = t.type({
  attackers_perspective: t.string,
  description: t.string,
  implication: t.string,
})

export const PerspectiveTypeAcs = t.union([t.literal('internal'), t.literal('external'), t.literal('')])

const _Attributes = {
  activity_type_description: t.string,
  activity_type_name: t.string,
  authority_display: t.boolean,
  authority_distance_max: t.number,
  authority_distance_min: t.number,
  configuration_id: t.string, // id unique to a configuration type
  created: _CreatedLastUpdated,
  cves: t.array(t.string),
  description: t.string,
  enabled: t.boolean,
  entities_count: t.number,
  last_planned_at: t.union([t.string, t.null]), // timestamp // confirm this can be null (from ACS doc)
  matching_entity_types: t.array(AuthoritativeEntityType),
  mitre: _Mitre,
  name: t.string,
  objective: _Objective,
  period: t.number,
  perspective_type: PerspectiveTypeAcs,
  required_authorization: t.number,
  stability: t.number,
  stealth: t.number,
  version: t.string,
  updated: _CreatedLastUpdated,
  targets_count: t.number,
  trigger_criteria: t.array(TriggerCriteria),
  parameters: t.array(Parameter),
}

export const ACAttributes = t.type(_Attributes)

// ----------------------------------------------------------------------------

export const ActivityConfiguration = t.type({
  attributes: ACAttributes,
  id: t.string, // uuid unique to the version of the configuration
  type: t.string, // activity_configuration
})
export type ActivityConfiguration = t.TypeOf<typeof ActivityConfiguration>

export const ActivityConfigurationNormalized = t.type({
  id: t.string,
  type: t.string,
  // flattened attributes below
  activity_type_description: t.string,
  activity_type_name: t.string,
  authority_display: t.boolean,
  authority_distance_max: t.number,
  authority_distance_min: t.number,
  configuration_id: t.string,
  created: _CreatedLastUpdated,
  cves: t.array(t.string),
  description: t.string,
  enabled: t.boolean,
  last_planned_at: t.union([t.string, t.null]),
  matching_entity_types: t.array(AuthoritativeEntityType),
  mitre: _Mitre,
  name: t.string,
  objective: _Objective,
  period: t.number,
  perspective_type: PerspectiveTypeAcs,
  required_authorization: t.number,
  stability: t.number,
  stealth: t.number,
  version: t.string,
  updated: _CreatedLastUpdated,
  trigger_criteria: t.array(TriggerCriteria),
  parameters: t.array(Parameter),
})
export type ActivityConfigurationNormalized = t.TypeOf<typeof ActivityConfigurationNormalized>

export const ActivityConfigurationsNormalized = t.array(ActivityConfigurationNormalized)
export type ActivityConfigurationsNormalized = t.TypeOf<typeof ActivityConfigurationsNormalized>

export const ActivityConfigurationsResponse = t.type({
  data: t.array(ActivityConfiguration),
  links: PaginatedLink,
  meta: PaginationMeta,
})
export type ActivityConfigurationsResponse = t.TypeOf<typeof ActivityConfigurationsResponse>

export const ActivityConfigurationResponse = t.type({
  data: ActivityConfiguration,
  links: Link,
})
export type ActivityConfigurationResponse = t.TypeOf<typeof ActivityConfigurationResponse>

export const ActivityConfigurationPatchResponse = t.type({
  data: ActivityConfiguration,
  links: t.null,
})
export type ActivityConfigurationPatchResponse = t.TypeOf<typeof ActivityConfigurationPatchResponse>
