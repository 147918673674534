/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNilOrEmpty } from '@randori/rootkit'
import { pathOr } from 'ramda'

import * as Store from '@/store'
import * as Logger from '@/utilities/logger'

import type { EntityType } from '../entity-types'
import { getSchemaForEntityType } from './get-schema-for-entity-type'

export const getViewSchema = (
  spec: ReturnType<typeof Store.SessionSelectors.selectOpenApiSpec>,
  entityType: EntityType,
) => {
  const schemaKey = getSchemaForEntityType(entityType)

  const viewSchema = pathOr({}, ['components', 'schemas', schemaKey, 'properties'], spec)

  if (!isNotNilOrEmpty(viewSchema)) {
    Logger.log(`Could not produce schema for entity-type: ${entityType}`)
  }

  return viewSchema
}

export const getActivityConfigurationViewSchema = (
  spec: ReturnType<typeof Store.SessionSelectors.selectOpenApiSpec>,
) => {
  const schemas = pathOr({}, ['components', 'schemas'], spec)

  const attributesSchema = pathOr({}, ['cmspb.FrontendConfiguration_Attributes', 'properties'], schemas)
  const createdUpdatedBy = pathOr({}, ['cmspb.FrontendConfiguration_AccessEntry', 'properties', 'author_id'], schemas)
  // The spec that comes from these dateTime fields in the openapi spec isn't accurate, and in the future,
  // the API should be updated to more accurately describe the value as { "format": "date-time", "type": "string"}
  // The ACS openapi spec generator translates to a spec like: { "nanos": "integer", "seconds": "integer" }
  const dateTime = pathOr({}, ['timestamppb.Timestamp', 'properties'], schemas)
  const id = pathOr({}, ['cmspb.FrontendConfiguration', 'properties', 'id'], schemas)
  const mitigations = pathOr({}, ['cmspb.FrontendConfiguration_Mitre', 'properties', 'mitigations'], schemas)
  const tactics = pathOr({}, ['cmspb.FrontendConfiguration_Mitre', 'properties', 'tactics'], schemas)
  const techniques = pathOr({}, ['cmspb.FrontendConfiguration_Mitre', 'properties', 'techniques'], schemas)
  const type = pathOr({}, ['cmspb.FrontendType', 'type'], schemas)

  const attackers_perspective = pathOr(
    {},
    ['cmspb.FrontendConfiguration_Objective', 'properties', 'attackers_perspective'],
    schemas,
  )
  const activity_description = pathOr(
    {},
    ['cmspb.FrontendConfiguration_Objective', 'properties', 'description'],
    schemas,
  )
  const implications = pathOr({}, ['cmspb.FrontendConfiguration_Objective', 'properties', 'implication'], schemas)

  // Construct a view schema that is the combination of the outer level 'id' and 'type' properties, and the extracted attributes properties
  const viewSchema = {
    id,
    type,
    ...attributesSchema,
    activity_description,
    attackers_perspective,
    created_by: createdUpdatedBy,
    created_on: dateTime,
    implications,
    last_planned_at: dateTime,
    mitre_mitigations: mitigations,
    mitre_tactics: tactics,
    mitre_techniques: techniques,
    updated_by: createdUpdatedBy,
    updated_on: dateTime,
  }

  // Delete these since they have been further extracted
  delete viewSchema['created' as keyof typeof viewSchema]
  delete viewSchema['updated' as keyof typeof viewSchema]
  delete viewSchema['mitre' as keyof typeof viewSchema]
  delete viewSchema['objective' as keyof typeof viewSchema]
  delete viewSchema['parameters' as keyof typeof viewSchema]
  delete viewSchema['trigger_criteria' as keyof typeof viewSchema]

  if (!isNotNilOrEmpty(viewSchema)) {
    Logger.log(`Could not produce schema for activity_configuration`)
  }

  return viewSchema
}

export const getExceptionPolicyViewSchema = (spec: ReturnType<typeof Store.SessionSelectors.selectOpenApiSpec>) => {
  const schemas = pathOr({}, ['components', 'schemas'], spec)

  const attributesSchema = pathOr({}, ['cmspb.FrontendExceptionPolicy_Attributes', 'properties'], schemas)
  const createdUpdatedBy = pathOr({}, ['cmspb.FrontendExceptionPolicy_AccessEntry', 'properties', 'author_id'], schemas)
  // The spec that comes from these dateTime fields in the openapi spec isn't accurate, and in the future,
  // the API should be updated to more accurately describe the value as { "format": "date-time", "type": "string"}
  // The ACS openapi spec generator translates to a spec like: { "nanos": "integer", "seconds": "integer" }
  const dateTime = pathOr({}, ['timestamppb.Timestamp', 'properties'], schemas)
  const id = pathOr({}, ['cmspb.FrontendExceptionPolicy', 'properties', 'id'], schemas)

  // Construct a view schema that is the combination of the outer level 'id' and 'type' properties, and the extracted attributes properties
  const viewSchema = {
    id,
    ...attributesSchema,
    created_by: createdUpdatedBy,
    created_on: dateTime,
    updated_by: createdUpdatedBy,
    updated_on: dateTime,
    exception_schedule: {
      type: 'string',
    },
  }

  // Delete these since they have been further extracted or will not be shown as columns
  delete viewSchema['created' as keyof typeof viewSchema]
  delete viewSchema['updated' as keyof typeof viewSchema]
  delete viewSchema['time_spans' as keyof typeof viewSchema]
  delete viewSchema['week_spans' as keyof typeof viewSchema]

  if (!isNotNilOrEmpty(viewSchema)) {
    Logger.log(`Could not produce schema for exception_policy`)
  }

  return viewSchema
}

// Due to Sources api not being added to the openapispec, we need to hardcode column options here
export const getSourceViewSchema = () => {
  const viewSchema = {
    id: { type: 'string' },
    name: { type: 'string' },
    status: { type: 'string' },
    last_checkin: { format: 'date-time', type: 'string' },
    source_type: { type: 'string' },
    implant_type: { type: 'string' },
    os_type: { type: 'string' },
    default_perspective_id: { type: 'string' },
    perspective_type: { type: 'string' },
    created_by: { type: 'string' },
    asset: { type: 'string' },
    create_time: { format: 'date-time', type: 'string' },
    update_time: { format: 'date-time', type: 'string' },
    implant_id: { type: 'string' },
  }

  if (!isNotNilOrEmpty(viewSchema)) {
    Logger.log(`Could not produce schema for source`)
  }

  return viewSchema
}

// Custom view schema for Assets since it involves several fields that are either not part of its native response and/or has fields that are part of a blackbox
export const getAssetViewSchema = () => {
  const viewSchema = {
    // native fields
    id: { type: 'string' },
    display_name: { type: 'string' },
    perspective_id: { type: 'string' },
    perspective_type: { type: 'string' },
    sources: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    first_seen: { format: 'date-time', type: 'string' },
    last_seen: { format: 'date-time', type: 'string' },

    // nested fields inside properties blackbox
    interfaces: {
      type: 'array',
      items: {
        type: 'string',
      },
    },

    // fields from source
    implant_type: { type: 'string' },
    os_type: { type: 'string' },
  }

  if (!isNotNilOrEmpty(viewSchema)) {
    Logger.log(`Could not produce schema for source`)
  }

  return viewSchema
}
